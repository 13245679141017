export enum MetricEvent {
  startGame = 'start_game',
  readArticle = 'read_article',
  wantSex = 'want_sex',
}

type SendMetric = {
  (event: MetricEvent.startGame, params: { type: 'paired' | 'classic' }): void;
  (event: MetricEvent.readArticle, params: { slug: string }): void;
  (event: MetricEvent.wantSex, params: { value: boolean }): void;
};

export const sendMetric: SendMetric = (event, params) => {
  window.ym?.(88921821, 'reachGoal', event, params);
};
